import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <form>
          <label>First Name </label>
          <input placeholder="Arash..." />
          <br />
          <label>Last Name </label>
          <input placeholder="Mosavi..." />
          <br />
          <button>send</button>
          <hr />
          <label>Full name : </label>
          <input type="email" name="fullname" />
          <br />
          <label>Email : </label>
          <input type="email" name="fullname" />
          <br />
          <label>Password : </label>
          <input type="password" name="fullname" />
          <button> save </button>
          <hr />
        </form>
      </header>
    </div>
  );
}

export default App;
